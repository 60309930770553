<template>
    <div class="tabs">
        
        <div class="topSearch">
            上报日期 <el-date-picker
                ref="tablePicker"
                v-model="time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                :picker-options="expireTimeOption"
                class="selectBox"
                style="width:360px"
            >
            </el-date-picker>
            数据类型 <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.dataType">
                <el-option
                    v-for="item in dataTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            系统阈值 <el-select class="selectBoxMi" size="small" placeholder="请选择" v-model="pageParams.dataStatus">
                <el-option
                    v-for="item in dataStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            是否处理 <el-select class="selectBoxMi" size="small" placeholder="请选择" v-model="pageParams.handleStatus">
                <el-option
                    v-for="item in handleStatus"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-input placeholder="请输入" size="small" class="selectBox input-with-select" v-model="pageParams.queryWord" style="width:200px">
                <el-select v-model="pageParams.queryType" slot="prepend" placeholder="请选择" style="width:100px">
                    <el-option label="设备名称" value="1"></el-option>
                    <el-option label="所在位置" value="2"></el-option>
                    <el-option label="监测点位" value="3"></el-option>
                    <el-option label="异常信息" value="4"></el-option>
                </el-select>
            </el-input>
            <div class="searchBtn">
                <el-button type="primary" size="small" @click="getList('reset')">查询</el-button>
                <el-button type="info" plain size="small" @click="reset()">重置</el-button>
            </div>
            <el-button type="primary" size="small" plain style="float:right;margin-top: 4px;" @click="exportData">导出</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            height="calc(100vh - 304px)"
            :row-class-name="tableRowClassName"
          >
            <el-table-column
                label="序号"
                type="index"
                width="52"
            >
            </el-table-column>
            <el-table-column
              v-for="col in head"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              align="left"
              show-overflow-tooltip
              :sortable="col.sorts"
              :width="col.widths"
            >
                <template v-if="col.isBar" #default="{ row }">
                  <div v-if="col.prop == 'val'">
                    {{ row.val }}{{row.unit}}
                  </div>
                  <div v-if="col.prop == 'handleStatus'">
                    {{ row.handleStatus == 1 ? '已处理' : '未处理' }}
                  </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="175">
                <template slot-scope="{ row }">
                    <span v-if="row.lightVoiceStatus == 1" class="linkBtn" style="margin-right:16px" @click="closeAlarm(row)">停止声光报警</span>
                    <span v-if="row.handleStatus == 0" class="linkBtn" @click="toDispose(row)">告警处置</span>
                    <span v-if="row.handleStatus == 1" class="linkBtn" @click="lookDispose(row)">查看处置</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageParams.current"
          :page-sizes="[30, 50, 100]"
          :page-size="pageParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="TablePages"
        >
        </el-pagination>

        <el-dialog
            :visible.sync="dialogVisible"
            width="470px"
            top="20vh"
            @close="closeDialog"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <div class="dialogTitles"><span></span> <b>告警处置</b></div>
            </template>
            <div class="note">
                {{disposeForm.handleNote}}
            </div>
            <div class="notes">
                <span>处置人：</span> {{disposeForm.handler}}
            </div>
            <div class="notes">
                <span>处置时间：</span> {{disposeForm.handleTime}}
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogVisibleHandle"
            width="470px"
            top="20vh"
            @close="closeDialogHandle"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <div class="dialogTitles"><span></span> <b>告警处置</b></div>
            </template>
            <p style="color:#0F0F0F">处置结果</p>
            <el-input class="textDom" type="textarea" placeholder="请输入" v-model="disposeForms.handleNote" :rows="3"></el-input>
            <div class="btnGroup">
                <el-button @click="dialogVisibleHandle = false">取消</el-button>
                <el-button type="primary" @click="saveHandle()">保存</el-button>
            </div>
        </el-dialog>
        <audio ref="audio" hidden src="../../../assets/warning.mp3" loop></audio>
    </div>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
import { exportMethod } from '@/common/js/exportExcel'
export default {
    name: 'abnormalData',
    components: {},
    data() {
        return {
            pageParams:{
                size: 100,
                current:1,
                dataStatus:'',
                dataType:'',
                handleStatus:'',
                queryWord:'',
                queryType:'1'
            },
            time:[],
            expireTimeOption: this.expireTimeOptionFun(),
            tableData:[],
            head:[
                { prop: 'equipmentName', label: '设备名称' },
                { prop: 'location', label: '所在位置' },
                { prop: 'monitorPointName', label: '监测点位'},
                { prop: 'val', label: '远传数据',isBar:true },
                { prop: 'warningDescribe', label: '异常信息' },
                { prop: 'dataStatus', label: '系统阈值',widths:'80px' },
                { prop: 'handleStatus', label: '是否处理',isBar:true,widths:'80px' },
                { prop: 'uploadTime', label: '上报时间' },
                { prop: 'collectTime', label: '采集时间' },
            ],
            total:0,
            dataTypeList:[],
            dataStatusList:[],
            handleStatus:[],
            dialogVisible:false,
            disposeForm:{
                handleNote:'',
                handler:'',
                handleTime:''
            },
            dialogVisibleHandle:false,
            disposeForms:{
                handleNote:'',
                id:''
            },
        }
    },
    mounted(){
        // 默认今天
        let dates = new Date()
        let ymd =
            dates.getFullYear() +
            '-' +
            (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
            '-' +
            (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
        let endTime = ymd + ' 23:59:59'
        let startTime = ymd + ' 00:00:00'
        this.time = [startTime, endTime]
        // 设备类型
        scadaApi.getOptions({names:"CollectionPointDataType,WarningLevel,HandleStatus"}).then(res => {
            this.dataTypeList = res.data.CollectionPointDataType
            this.dataStatusList = res.data.WarningLevel
            this.handleStatus = res.data.HandleStatus
        })
        // 获取列表
        this.getList()
    },
    computed: {
    },
    beforeDestroy() {
        if(this.$refs.audio){
            this.$refs.audio.pause()
        }
    },
    methods: {
        closeMusic() {
            let arr = document.getElementsByClassName('double').length
            if (arr == 0) {
                this.$refs.audio.pause() //停止
            }
        },
        playMusic(type) {
            this.$refs.audio.play()
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.lightVoiceStatus == 1) {
                return 'double'
            }
        },
        getList(reset){
            let that = this
            if(reset){
                this.pageParams.current = 1
                this.pageParams.size = 30
            }
            let obj = {
                ...this.pageParams
            }
            if(this.time){
                obj.startTime = this.time[0]
                obj.endTime = this.time[1]
            }
            scadaApi.getWarningPage(obj).then(res => {
                this.tableData = res.data.list
                this.total = res.data.total
                let palyAutio = false
                res.data.list.forEach(item => {
                    if(item.lightVoiceStatus == 1){
                        palyAutio = true
                    }
                })
                if(palyAutio){
                    setTimeout(() => {
                        that.playMusic()
                    },20)
                }else{
                    if(that.$refs.audio){
                        that.$refs.audio.pause()
                    }
                }
            })
        },
        reset(){
            let dates = new Date()
            let ymd =
                dates.getFullYear() +
                '-' +
                (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
                '-' +
                (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
            let endTime = ymd + ' 23:59:59'
            let startTime = ymd + ' 00:00:00'
            this.time = [startTime, endTime]
            this.pageParams.current = 1
            this.pageParams.size = 30
            this.pageParams.dataType = ''
            this.pageParams.dataStatus = ''
            this.pageParams.handleStatus = ''
            this.pageParams.queryType = '1'
            this.pageParams.queryWord = ''
            this.getList()
        },
        //关闭声光告警
        closeAlarm(row) {
            this.$confirm(`确定关闭${ row.equipmentName || '' }声光告警?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
            }).then(() => {
                scadaApi.stopLightVoice({id:row.id}).then(res => {
                    if (res.code === 200) {
                        this.getList()
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch()
        },
        lookDispose(row){
            scadaApi.handleInfo({id:row.id}).then(res => {
                this.disposeForm = res.data
                this.dialogVisible = true
            })
        },
        closeDialog(){
            this.disposeForm = {
                handleNote:'',
                handler:'',
                handleTime:''
            }
        },
        toDispose(row){
            this.disposeForms.id = row.id
            this.dialogVisibleHandle = true
        },
        saveHandle(){
            if(this.disposeForms.handleNote){
                scadaApi.handleWarning(this.disposeForms).then(res => {
                    this.$message.success(res.msg)
                    this.getList()
                    this.dialogVisibleHandle = false
                })
            }else{
                this.$message.info('请输入告警处置')
            }
        },
        closeDialogHandle(){
            this.disposeForms={
                handleNote:'',
                id:''
            }
        },
        expireTimeOptionFun() {
            let selectData = []
            return {
                onPick: ({ maxDate, minDate }) => {
                    selectData[0] = minDate?.getTime()
                    selectData[1] = maxDate?.getTime()
                },
                disabledDate(time) {
                    //选择时间
                    if (selectData[0] && !selectData[1]) {
                        return (
                            time.getTime() > selectData[0] + 24 * 60 * 60 * 6 * 1000 ||
                            time.getTime() < selectData[0] - 24 * 60 * 60 * 6 * 1000
                        )
                    }
                }
            }
        },
        exportData(){
           let obj = JSON.parse(JSON.stringify(this.pageParams))
           if(this.time){
                obj.startTime = this.time[0]
                obj.endTime = this.time[1]
            }
           obj.current = 0
           obj.size = -1
           scadaApi.exportWarn(obj).then(res => {
                exportMethod(res,'异常数据')
           }) 
        },
        handleSizeChange(e){
            this.pageParams.size = e
            this.getList()
        },
        handleCurrentChange(e){
            this.pageParams.current = e
            this.getList()
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep.input-with-select .el-input-group__prepend{
    background-color: #fff;
    color: #0F0F0F;
}
.linkBtn{
    cursor: pointer;
    color: #1082FF;
}
::v-deep .double{
  animation:beat 1s infinite;
}
@keyframes beat{
  0%{transform: scale(1,1); opacity: 1;background-color: #fff;color:red}
  100%{transform: scale(1,1); opacity: 1;background-color: red;color:#fff}
}
.note{
    color: #303133;
    font-size: 14px;
    line-height: 28px;
    padding-bottom: 20px;
    border-bottom: 1px solid #EBEBEB;
    span{
        color: #909399;
    }
}
.notes{
    font-size: 14px;
    color: #303133;
    padding-top: 14px;
    span{
        color: #909399;
        font-size: 14px;
    }
}
.btnGroup{
    padding-top: 12px;
    text-align: center;
}
.textDom{
    padding-bottom: 20px;
    padding-top: 6px;
    border-bottom: 1px solid #EBEBEB;
}
.tabs {
  margin: 10px;
  background-color: #fff;
  height: calc(100vh - 206px);
  padding: 16px 10px;
  .topSearch{
        color: #0F0F0F;
        padding: 0px 0px 16px 10px;
        .selectBox{
            width: 200px;
            margin: 0 30px 0 0;
        }
        .selectBoxMi{
            width: 120px;
            margin: 0 30px 0 0;
        }
        .searchBtn{
            display: inline-block;
        }
    }
}
.TablePages{
    margin: 16px 0;
    padding-left: 0;
}
</style>